
  
  h1 {
    font-size: 30px !important;
    font-weight: 700 !important;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    

  }
  
  h1::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #149ddd;
    bottom: 0;
    left: 0;
  }
  h2 {
    font-size: 26px !important;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    

  }
  .container{
    padding: 35px;
    
  }
  .containerbackground{
    background-color: #303030;

  }
  .container p {
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 20px;
  }
  blockquote {
    font-style: italic;
    margin: 0;
    padding: 0 0 0 1em;
    border-left: 1px solid #839496;
  }
  a {
    font-weight: bold;
  }