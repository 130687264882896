.paper{
  background-color: #fff;
  color: rgb(26, 26, 26);

}


.cv .table {
  background-color: #fff;
  color: rgb(26, 26, 26);
}
.cv .cv-title {
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    color:#149ddd

  }
  
  .cv .cv-item {
    padding: 0 0 20px 20px;
    margin-top: -2px;
    border-left: 2px solid #149ddd;
    position: relative;
  }
  
  .cv .cv-item h3 {
    line-height: 18px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
  }
  .cv .cv-item h4 {
    line-height: 15px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
  }
  .cv .cv-item h5 {
    font-size: 16px;
    background: #57595a;
    color:#fff;
    padding: 5px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .cv .cv-item ul {
    padding-left: 20px;
  }
  
  .cv .cv-item ul li {
    padding-bottom: 10px;
  }
  
  .cv .cv-item:last-child {
    padding-bottom: 0;
  }
  
  